.decor-details {
    background-color: whitesmoke;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.decor-details > div:first-child {
    margin-top: 8%;
    margin-bottom: 3%;
}

.decor-details > div {
    display: flex;
    flex-direction: row;
    width: 95%;
}

.decor-details > div:nth-child(2) {
    margin: 0 auto;
    margin-bottom: 0;
    overflow: hidden;
}

.decor-details > div:nth-child(2) > div:first-child  {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
}

.decor-details > div:nth-child(2) > div:first-child > img {
    height: 400px;
    width: 500px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.decor-details > div:nth-child(2) > div:first-child > div {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 600px;
    width: fit-content;
    margin-left: 15px;
}

.decor-details > div:nth-child(2) > div:first-child > div > a > img {
    height: 100px;
    width: 100px;
    margin-bottom: 15px;
    margin-right: 15px;
    transition-duration: 0.3s;
    cursor: pointer;
}

.decor-details > div:nth-child(2) > div:first-child > div > a > img:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.decor-details > div:nth-child(2) > div {
    width: 80%;
}

.decor-details > div:nth-child(2) > div > header {
    font-size: 35px;
    font-family: title;
    text-align: center;
    text-transform: capitalize;
}

.decor-details > div:nth-child(2) > div > p {
    font-size: 20px;
    font-family: luxe;
    text-align: center;
}

.decor-details > div:nth-child(2) > div:nth-child(2)> div:nth-child(4)   {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}

.decor-details > div:nth-child(2) > div:nth-child(2)> div:nth-child(4)> span  {
    background-color:lightgray;
    padding: 10px;
    border-radius: 15px;
    font-size: 25px;
    margin: 5px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    
}


.decor-details > div:nth-child(3){
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    margin-top: 20px;
    max-height: 450px;
    overflow: hidden;
}
.decor-details > div:nth-child(3) > div{
    width: 50%;
    
}

.decor-details > div:nth-child(3) > img{
    margin-right: 15px;
    max-width: 60%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.decor-details > div:nth-child(3) > div > div:nth-child(2) > div > span:nth-child(1){
    font-family: luxe;
    font-size: 25px;
}

.decor-details > div:nth-child(3) > div > div:nth-child(2) > div > span:nth-child(2){
    font-family: luxe;
    font-size: 25px;
    color: rgb(150, 148, 148);
}

.decor-details > div:nth-child(3) > div > div:nth-child(2) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 60px;
}

.decor-details > div:nth-child(5){
    width: fit-content;
    margin: 10px auto;
    border-radius: 15px;    
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.decor-details > div:nth-child(5) > img{
    border-radius: 15px;    
}

.decor-details > div:nth-child(5) > a > div {
    display: flex;
    flex-direction: row;
    font-size: 20px;
}

@media (max-width: 1000px){
    
    .decor-details > div{
        flex-direction: column;
        
    }

    .decor-details > div:first-child {
        flex-direction: row;  
        margin-top: 20%; 
    }

    

    .decor-details > div:nth-child(2) > img {
        width: 95%;
        margin-top: 0;
        margin-bottom: 10px;
    }

    .decor-details > div:nth-child(2)  {
        max-height: fit-content;
    }

    .decor-details > div:nth-child(3) {
        flex-direction: column;
        max-height: fit-content;
        
    }

    .decor-details > div:nth-child(2) > div:nth-child(2) {
        width: 100%;
    }

    .decor-details > div:nth-child(3) > div{
        width: 100%;
    }

    .decor-details > div:nth-child(3) > img{
        margin-right: 0;
        width: 90%;
        margin: 15px auto;
    }

    .decor-details > div:nth-child(3) > div > div:nth-child(2) > div > span:nth-child(1){
        font-size: 18px;
    }

    .decor-details > div:nth-child(3) > div > div:nth-child(2) > div > span:nth-child(2){
        font-size: 18px;
    }

    .decor-details > div:nth-child(5) > img{
        width: 100%;    
    }
    .decor-details > div:nth-child(2) > div:first-child  {
        flex-direction: column;
    }
    .decor-details > div:nth-child(2) > div:first-child > div {
        height: fit-content;
        flex-direction: row;

    }

    .decor-details > div:nth-child(2) > div:first-child > img {
        height: fit-content; 
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }

    .decor-details > div:nth-child(2) > div:first-child > div > a > img {
        height: 70px;
        margin-top: 15px;
        margin-right: 15px;
        transition-duration: 0.3s;
        cursor: pointer;
    }
    .decor-details > div:nth-child(5) > img{
        width: 100%;
        border-radius: 15px;    
    }
}