
.home{
    width: 100%;
    height: 115vh;
    background-image: url('../../../public/images/home/home-1.png');
    background-size: cover;
    background-position: center;
    transition: background-image 0.5s ease; 
}
.shadow-image{
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end; /* align items to the left */
}

.shadow-image > p {
    background-color: rgba(0, 0, 0, 0.5);
    border-top-left-radius: 80px;
    border-bottom-left-radius: 80px;
    letter-spacing: 0.15em;
    width: 55%;
    height: fit-content;
    text-align: right;
    color: whitesmoke;
    font-size:30px;
    margin-top: 150px;
    padding: 15px;
}

.shadow-image > span {
    width: fit-content;
    color: whitesmoke;
    font-size: 23px;
    padding: 10px 50px;
    color: whitesmoke;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background-color: #a5ce3b;
    transition: color 0.5s ease, background-color 0.5s ease;
    cursor: pointer;
    text-transform: uppercase;
    margin-right: 30px;
}

.shadow-image> span:hover {
    background-color: #893f1e;
}

.see-more{
    width: fit-content;
    color: whitesmoke;
    font-size: 23px;
    padding: 10px 50px;
    color: whitesmoke;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background-color: #a5ce3b;
    transition: color 0.5s ease, background-color 0.5s ease;
    cursor: pointer;
    text-transform: uppercase;
    display: flex;
    margin: auto;
}

.see-more:hover {
    background-color: #893f1e;
}


.projects {
    /*background-image: url('../../../public/images/background-3.jpg');
    background-size: cover;
    background-repeat: no-repeat;*/
    background-color: whitesmoke;
    display: flex;
    flex-direction: column;
    width: 100%;
}
.projects div {
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    margin-left: 100px;
}

.projects > div > img {
    width: 30%;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.projects > div > p{
    width: 50%;
    font-size: 25px;
    text-align: justify;
    margin-left: 50px;
    margin-right: 40px;
}

.gallery{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 85%;
    margin: 0 auto;
}

.gallery > div {
    width: 32%;
    display: flex;
    flex-direction: column;
    background-size: cover;
    background-position: center;
    height: 300px;
    margin-bottom: 10px;
    border: 1px solid lightgray;
    justify-content: flex-end;
    overflow: hidden;
    cursor: pointer;
}

.gallery > div:hover > header {
    visibility: visible;
    opacity: 1;
    transition-duration: 0.5s;
    
}

.gallery > div > header {
    width: 100%;
    padding: 30px 0;
    padding-left: 30px;
    font-size: 30px;
    background-color: white;
    font-family: title;
    transition-duration: 0.1s;
    opacity: 0;
    text-transform: uppercase;
    display: flex;
    visibility: hidden;
}

.gallery > div > header:hover {
    letter-spacing: 0.02em;
    color: #a5ce3b;
}

.gallery > div:nth-child(1) {
    background-image: url('../../../public/images/home/home-office.jpg');
}
.gallery > div:nth-child(2) {
    background-image: url('../../../public/images/projects/photo-2.jpg');
}
.gallery > div:nth-child(3) {
    background-image: url('../../../public/images/projects/hospital.jpeg');
}
.gallery > div:nth-child(4) {
    background-image: url('../../../public/images/projects/university.png');
}
.gallery > div:nth-child(5) {
    background-image: url('../../../public/images/projects/interior.jpeg');
}
.gallery > div:nth-child(6) {
    background-image: url('../../../public/images/home/home-1.png');
}

.banner {
    width: 100%;
    height: 50vh;
    background-image: url('../../../public/images/banners/banner-2.jpg');
    background-size: cover;
    background-position: center;
    margin: 50px 0;
}

.banner > div {
    justify-content: flex-end;
    align-items: center;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom right, rgba(165, 206, 59, 0.6), rgba(0, 0, 0, 0.5));
}

.banner > div > div {
    margin-right: 30px;
    max-height: 90%;
    max-width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.banner > div > div > header{
    font-family: title;
    font-size: 40px;
    letter-spacing: 0.1em;
    color: white;
}

.banner > div > div > p {
    font-size: 20px;
    color: white;
    text-align: center;
    max-width: 80%;
}

.banner > div > div > span {
    font-size: 22px;
    padding: 10px 20px;
    color: white;
    background-color: rgba(165, 206, 59, 0.9);
    border-radius: 4px;
    cursor: pointer;
}

.banner > div > div > span:hover {
    background-color: rgba(165, 206, 59, 1);
}

.popular{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}

.popular > a {
    display: flex;
    width: 20%;
    height: 250px;
    margin-left: 5px;
    margin-bottom: 20px;
    border: 1px solid lightgray;
    text-decoration: none;
    color: black;
}

.popular > a > div {
    display: flex;
    width: 100%;
}

.popular > a > div > span {
    width: 100%;
    margin-top: 70%;
    padding-left: 30px;
    padding-top: 15px;
    font-size: 18px;
    background-color: white;
    font-family: title;
    transition-duration: 0.1s;
    text-transform: uppercase;
}


.popular > a:nth-child(1) > div{
    background-image: url('../../../public/images/products/photo-7.jpg');
    background-size: cover;
}

.popular > a:nth-child(2) > div{
    background-image: url('../../../public/images/products/popular-4.jpg');
    background-size: cover;
}

.popular > a:nth-child(3) > div{
    background-image: url('../../../public/images/products/placo.jpg');
    background-size: cover;
}

.popular > a:nth-child(4) > div{
    background-image: url('../../../public/images/products/gerflex.jpg');
    background-size: cover;
}
.popular > a:nth-child(5) > div{
    background-image: url('../../../public/images/products/paillasson.jpg');
    background-size: cover;
}

.popular > a:nth-child(6) > div{
    background-image: url('../../../public/images/home/home-1.png');
    background-size: cover;
}

.popular > a:nth-child(7) > div{
    background-image: url('../../../public/images/products/bardage-2.png');
    background-size: cover;
}

.popular > a:nth-child(8) > div{
    background-image: url('../../../public/images/products/store.webp');
    background-size: cover;
}

.partners {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 150px;
    width: 95%;
    margin: auto;
}


@media (max-width: 1000px) {
    .home{
        width: 100%;
        height: 28vh;
    }

    .shadow-image{
        align-items: center; /* align items to the left */
    
    }

    .shadow-image > p {
        width: 85%;
        font-size: 17px;
        margin-top: 0;
        text-align: center;
        border-top-right-radius: 80px;
        border-bottom-right-radius: 80px;
        padding: 20px;
    }
    
    .shadow-image > span {
        font-size: 20px;
        margin-right: 0;
    }
    .popular{
        flex-direction: column;
    }

    .popular > a {
        width: 95%;
        height: fit-content;
        margin: 50px auto;
        border: 1px solid lightgray;
    }

    .popular > div > span {
        height: 50px;
        font-size: 25px
    }

    .gallery > div {
        width: 90%;
        height: 300px;
    }
    
    .banner > div {
        justify-content: center;
        
    }
    .banner > div > div {
        max-width: 90%;
        
    }
    .banner > div > div > p {
        text-align: center;
        max-width: 100%;
    }

    .footer {
        flex-direction: column;
        height: fit-content;
    }

    .footer > div  {
        width: 90%;
        height: fit-content;
    }

    .footer > div > div {
        margin-top: 15px;
    }

    .footer > div > div:nth-child(4), .footer > div > div:nth-child(6){
        margin-top: 0;
    }
    
    
    .footer > div > div:nth-child(4) > img, .footer > div > div:nth-child(6) > img{
        height: 17px;
    }

}