.whatsapp {
    display: flex;
    border-radius: 100%;
    width: 80px;
    height: 80px;
    position: fixed;
    top: 85%;
    right: 94%;
    background-color: #1DA110;
    align-items: center;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    transition-duration: 0.3s;
}
/*service id : service_tpcleho*/

.whatsapp > img {
    margin: auto;
    width: 75%;
    height: 75%;
}

@media (max-width: 1000px){
    .whatsapp {
        left: 80%;
    }

}